<template>
    <tr v-on="{ click: clickable ? onClick : () => null }" :class="clickable ? 'clickable' : ''">
        <td width="40%" class="py-2">
            <div v-if="item.created_at">{{ item.created_at | datetime }}</div>
            <div>{{ $t('tables.ImportEntryTable.item.created_by', { created_by: item.created_by.name }) }}</div>
        </td>
        <td width="30%" class="py-2">{{ item.type_i18n }}</td>
        <td width="15%" class="py-2">
            <v-chip label :color="item.status_color" outlined small>
                <v-icon small left v-if="item.status_icon">{{ item.status_icon }}</v-icon>
                {{ item.status_i18n }}
            </v-chip>
        </td>
        <td width="15%" class="py-2">
            <div v-if="item.progress_percentage">
                {{ parseInt(item.progress_percentage) | percentage }}
            </div>
            <div v-else>
                -
            </div>
        </td>
    </tr>
</template>
<script>
export default {
    name: 'ImportEntryTableItem',
    props: {
        item : { type: Object, required: true },
        index: { type: Number, required: true },
        clickable: Boolean,
    },
    methods: {
        onClick() {
            this.$emit('click')
        },
    },
}
</script>