<template>
    <v-container fluid class="pt-0">
        <ChooseImportTypeModal ref="choose_import_type_modal"/>

        <span class="pl-5 pl-md-12 section-title">{{ $t('views.import.List.title') }}</span>
        <v-alert v-if="hasError" type="error" class="mt-4 mx-5 mt-md-6 mx-md-12">
            {{ $t('errors.generic') }}
        </v-alert>
        <ImportEntryTable 
            v-else
            ref="table"
            class="my-4 mx-2 mx-md-10"
            :fetchFunction="fetchFunction"
            @openImport="handleOpenImport"
            @selected="viewImportEntry"
            @refresh="refreshTable"
        />
    </v-container>
</template>

<script>
import ImportEntryTable      from '@/components/tables/ImportEntryTable'
import HasErrorHandlerMixin  from '@/mixins/HasErrorHandlerMixin'
import ChooseImportTypeModal from './ChooseImportTypeModal'
import ImportEntry           from '@/models/ImportEntry'

export default {
    data: () => {
        return {
            hasError: false,
            interval        : null,
            REFRESH_INTERVAL: 5000,
        }
    },
    mixins: [ HasErrorHandlerMixin ],
    components: { ImportEntryTable, ChooseImportTypeModal },
    async created() {
        // Vai atualizar a tabela a cada intervalo fixo
        this.interval = setInterval(async () => { await this.refreshTable() }, this.REFRESH_INTERVAL)
    },
    beforeDestroy() {
        clearInterval(this.interval)
    },
    methods: {
        async refreshTable() {
            await this.$refs.table.refresh()
        },
        async fetchFunction(pagination) {
            this.hasError = false
            let response  = await ImportEntry.list(pagination)
                .catch(this.preErrorHandler)
            return ImportEntry.hydrate(this.$lodash.get(response, 'data', []))
        },
        preErrorHandler(e) {
            this.hasError = true
            this.errorHandler(e)
        },
        async handleOpenImport() {
            let to = await this.$refs.choose_import_type_modal.open()
            if (!to)
                return
            this.$router.push(to)
        },
        viewImportEntry(props) {
            this.$router.push({ name: 'import.view', params: { id: props.item.id } })
        },
    },
}
</script>