<template>
    <BaseModal ref="modal"
        @cancel="cancel"
        v-model="isModalOpen"
        :title="title"
        :confirmButton="computedConfirmButton"
        :cancelButton="computedCancelButton"
        persistent
        :width="400"
        cardTextClass="px-0"
    >
        <v-list>
            <v-list-item @click="selectType({ name: 'import.customer' })">
                <v-list-item-avatar>
                    <v-icon color="primary">$cp_user</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                    <v-list-item-title>
                        {{ $t('modals.ChooseImportType.types.customer.title') }}
                    </v-list-item-title>
                    <v-list-item-subtitle v-if="$vuetify.breakpoint.smAndUp">
                        {{ $t('modals.ChooseImportType.types.customer.subtitle') }}
                    </v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>

            <!-- [TODO] Deixar esta lista dinâmica quando for adicionar mais tipos de importação -->
        </v-list>

    </BaseModal>
</template>

<script>
import BaseModal      from '@/components/modals/BaseModal'
import BaseModalMixin from '@/components/modals/BaseModalMixin'
import Prize          from '@/models/Prize'

export default {
    name: 'ChooseImportType',
    components: { BaseModal },
    mixins: [ BaseModalMixin ],
    data: vm => ({
        title: vm.$t('modals.ChooseImportType.title'),
    }),
    methods: {
        selectType(to) {
            this.resolveValue = to
            this.confirm()
        },
    },
    computed: {
        computedConfirmButton() {
            return {
                hide: true,
            }
        },
        computedCancelButton() {
            return {
                
            }
        },
    },
}
</script>