var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "pt-0 px-5 px-md-12", attrs: { fluid: "" } },
    [
      _c(
        "div",
        {
          staticClass:
            "d-flex flex-column flex-sm-row align-sm-center justify-sm-space-between"
        },
        [
          _c("span", { staticClass: "pl-3 section-title" }, [
            _vm._v(_vm._s(_vm.$t("views.import.view.title")))
          ]),
          _c(
            "v-btn",
            {
              staticClass: "mt-6 mt-sm-0 mr-3",
              attrs: {
                outlined: "",
                height: "36px",
                width: _vm.$vuetify.breakpoint.smAndUp ? "130px" : "100%",
                disabled: _vm.loading,
                color: "primary white--text"
              },
              on: { click: _vm.fetchImportEntry }
            },
            [
              _c("v-icon", { staticClass: "mr-3", attrs: { small: "" } }, [
                _vm._v("$cp_refresh")
              ]),
              _vm._v(" " + _vm._s(_vm.$t("actions.refresh")) + " ")
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-card",
        { staticClass: "mt-4 mb-2 mx-0 mx-md-1 pa-4", attrs: { flat: "" } },
        [
          _c(
            "v-card-text",
            { staticClass: "py-0" },
            [
              _vm.loading
                ? _c("v-progress-linear", { attrs: { indeterminate: "" } })
                : _vm._e(),
              _vm.importEntry
                ? [
                    _c(
                      "v-row",
                      [
                        _c("v-col", { staticClass: "py-1" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("views.import.view.info.id.label")
                              ) +
                              " "
                          )
                        ]),
                        _c("v-col", { staticClass: "py-1" }, [
                          _vm._v(" " + _vm._s(_vm.id) + " ")
                        ])
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      [
                        _c("v-col", { staticClass: "py-1" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("views.import.view.info.type.label")
                              ) +
                              " "
                          )
                        ]),
                        _c("v-col", { staticClass: "py-1" }, [
                          _vm._v(" " + _vm._s(_vm.importEntry.type_i18n) + " ")
                        ])
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      [
                        _c("v-col", { staticClass: "py-1" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("views.import.view.info.status.label")
                              ) +
                              " "
                          )
                        ]),
                        _c(
                          "v-col",
                          { staticClass: "py-1" },
                          [
                            _c(
                              "v-chip",
                              {
                                attrs: {
                                  label: "",
                                  color: _vm.importEntry.status_color,
                                  outlined: "",
                                  "x-small": ""
                                }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.importEntry.status_i18n) +
                                    " "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      [
                        _c("v-col", { staticClass: "py-1" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "views.import.view.info.created_at.label"
                                )
                              ) +
                              " "
                          )
                        ]),
                        _c("v-col", { staticClass: "py-1" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("datetime")(_vm.importEntry.created_at)
                              ) +
                              " "
                          )
                        ])
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      [
                        _c("v-col", { staticClass: "py-1" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "views.import.view.info.created_by.label"
                                )
                              ) +
                              " "
                          )
                        ]),
                        _c("v-col", { staticClass: "py-1" }, [
                          _vm._v(
                            " " + _vm._s(_vm.importEntry.created_by.name) + " "
                          )
                        ])
                      ],
                      1
                    ),
                    _vm.importEntry.file_url
                      ? _c(
                          "v-row",
                          [
                            _c("v-col", { staticClass: "py-1" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "views.import.view.info.file_url.label"
                                    )
                                  ) +
                                  " "
                              )
                            ]),
                            _c("v-col", { staticClass: "py-1" }, [
                              _c(
                                "a",
                                { attrs: { href: _vm.importEntry.file_url } },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "mr-2",
                                      attrs: { small: "" }
                                    },
                                    [_vm._v("$cp_file")]
                                  ),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "views.import.view.info.file_url.value"
                                        )
                                      ) +
                                      " "
                                  )
                                ],
                                1
                              )
                            ])
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.importEntry.hasProgress()
                      ? [
                          _c("v-divider", { staticClass: "my-3" }),
                          _c("h3", { staticClass: "mb-4 primary--text" }, [
                            _vm._v(
                              _vm._s(_vm.$t("views.import.view.progress.title"))
                            )
                          ]),
                          _c(
                            "v-row",
                            [
                              _c("v-col", { staticClass: "py-1" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "views.import.view.progress.validate_progress.label"
                                      )
                                    ) +
                                    " "
                                )
                              ]),
                              _c("v-col", { staticClass: "py-1" }, [
                                _c("div", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "views.import.view.progress.progress.value",
                                        {
                                          row: _vm.importEntry.lastValidatedRow
                                        }
                                      )
                                    )
                                  )
                                ])
                              ])
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c("v-col", { staticClass: "py-1" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "views.import.view.progress.process_progress.label"
                                      )
                                    ) +
                                    " "
                                )
                              ]),
                              _c("v-col", { staticClass: "py-1" }, [
                                _c("div", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "views.import.view.progress.progress.value",
                                        {
                                          row: _vm.importEntry.lastProcessedRow
                                        }
                                      )
                                    )
                                  )
                                ]),
                                _vm.importEntry.lastProcessedRow
                                  ? _c("small", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "views.import.view.progress.process_progress.hint",
                                            {
                                              row:
                                                _vm.importEntry.lastProcessedRow
                                            }
                                          )
                                        )
                                      )
                                    ])
                                  : _vm._e()
                              ])
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-progress-linear", {
                                    attrs: {
                                      value:
                                        _vm.importEntry.progress_percentage,
                                      height: "25",
                                      rounded: "",
                                      color: _vm.importEntry.status_color
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(ref) {
                                            var value = ref.value
                                            return [
                                              _c("strong", [
                                                _vm._v(
                                                  _vm._s(parseInt(value)) + "%"
                                                )
                                              ])
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      899767932
                                    )
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ]
                      : _vm._e(),
                    _vm.importEntry.hasError()
                      ? [
                          _c("v-divider", { staticClass: "my-3" }),
                          _c("h3", { staticClass: "mb-4 primary--text" }, [
                            _vm._v(
                              _vm._s(_vm.$t("views.import.view.error.title"))
                            )
                          ]),
                          _vm.importEntry.error_message_i18n
                            ? _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    { staticClass: "pb-0 mb-2" },
                                    [
                                      _c(
                                        "v-alert",
                                        { attrs: { type: "error" } },
                                        [
                                          _c("div", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.importEntry
                                                  .error_message_i18n
                                              )
                                            )
                                          ]),
                                          _vm.$lodash.get(
                                            _vm.importEntry,
                                            "status_details.data.validationReturn"
                                          )
                                            ? _c("div", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.importEntry
                                                        .status_details.data
                                                        .validationReturn
                                                    ) +
                                                    " "
                                                )
                                              ])
                                            : _vm._e(),
                                          _vm.importEntry.status_details.code ==
                                          "DUPLICATED_COLUMN_VALUE"
                                            ? [
                                                _c("div", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.importEntry.status_details.data.repeatedValues.join(
                                                        ", "
                                                      )
                                                    )
                                                  )
                                                ])
                                              ]
                                            : _vm._e()
                                        ],
                                        2
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.importEntry.status_details.message
                            ? _c(
                                "v-row",
                                [
                                  _c("v-col", { staticClass: "py-1" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "views.import.view.error.message.label"
                                          )
                                        ) +
                                        " "
                                    )
                                  ]),
                                  _c("v-col", { staticClass: "py-1" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.importEntry.status_details.message
                                        ) +
                                        " "
                                    )
                                  ])
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.importEntry.status_details.code
                            ? _c(
                                "v-row",
                                [
                                  _c("v-col", { staticClass: "py-1" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "views.import.view.error.code.label"
                                          )
                                        ) +
                                        " "
                                    )
                                  ]),
                                  _c("v-col", { staticClass: "py-1" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.importEntry.status_details.code
                                        ) +
                                        " "
                                    )
                                  ])
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.importEntry.status_details.data
                            ? _c(
                                "v-row",
                                [
                                  _c("v-col", { staticClass: "py-1" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "views.import.view.error.status_details.label"
                                          )
                                        ) +
                                        " "
                                    )
                                  ]),
                                  _c("v-col", { staticClass: "py-1" }, [
                                    _c(
                                      "code",
                                      {
                                        staticClass: "pa-4 elevation-0 code-box"
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.importEntry.status_details.data
                                          )
                                        )
                                      ]
                                    )
                                  ])
                                ],
                                1
                              )
                            : _vm._e()
                        ]
                      : _vm._e()
                  ]
                : _vm._e()
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }