var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("BaseCrudTable", {
    ref: "table",
    attrs: {
      tableName: "ImportEntryTable",
      headerConfigs: _vm.headerConfigs,
      fetchFunction: _vm.fetchFunction,
      pagination: _vm.pagination,
      createBtnHidden: true,
      showActions: false
    },
    on: {
      "update:loading": function(value) {
        return (_vm.loading = value)
      }
    },
    scopedSlots: _vm._u([
      {
        key: "actions",
        fn: function() {
          return [
            _c(
              "v-tooltip",
              {
                attrs: { bottom: "" },
                scopedSlots: _vm._u([
                  {
                    key: "activator",
                    fn: function(ref) {
                      var on = ref.on
                      var attrs = ref.attrs
                      return [
                        _c(
                          "v-btn",
                          _vm._g(
                            _vm._b(
                              {
                                directives: [
                                  {
                                    name: "can",
                                    rawName: "v-can:create",
                                    value: "ImportEntry",
                                    expression: "'ImportEntry'",
                                    arg: "create"
                                  }
                                ],
                                staticClass: "mr-0 mr-sm-4",
                                attrs: { icon: "", small: "" },
                                on: {
                                  click: function($event) {
                                    return _vm.$emit("refresh")
                                  }
                                }
                              },
                              "v-btn",
                              attrs,
                              false
                            ),
                            on
                          ),
                          [
                            _c(
                              "v-icon",
                              {
                                staticClass: "secondary_font--text",
                                attrs: { small: "" }
                              },
                              [_vm._v("$cp_refresh")]
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              },
              [_c("span", [_vm._v(_vm._s(_vm.$t("actions.import")))])]
            )
          ]
        },
        proxy: true
      },
      {
        key: "main-btn",
        fn: function() {
          return [
            _c(
              "v-btn",
              {
                class: _vm.$vuetify.breakpoint.smAndUp
                  ? "mr-3 primary--text"
                  : "primary--text",
                attrs: {
                  block: !_vm.$vuetify.breakpoint.smAndUp,
                  outlined: "",
                  loading: _vm.loading,
                  height: "36px",
                  width: "86px"
                },
                on: {
                  click: function($event) {
                    return _vm.$emit("openImport")
                  }
                }
              },
              [_vm._v(_vm._s(_vm.$t("views.import.List.add")))]
            )
          ]
        },
        proxy: true
      },
      {
        key: "item",
        fn: function(props) {
          return [
            _c(
              "ImportEntryTableItem",
              _vm._b(
                {
                  attrs: { clickable: "" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("selected", props)
                    }
                  }
                },
                "ImportEntryTableItem",
                props,
                false
              )
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }