var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseModal",
    {
      ref: "modal",
      attrs: {
        title: _vm.title,
        confirmButton: _vm.computedConfirmButton,
        cancelButton: _vm.computedCancelButton,
        persistent: "",
        width: 400,
        cardTextClass: "px-0"
      },
      on: { cancel: _vm.cancel },
      model: {
        value: _vm.isModalOpen,
        callback: function($$v) {
          _vm.isModalOpen = $$v
        },
        expression: "isModalOpen"
      }
    },
    [
      _c(
        "v-list",
        [
          _c(
            "v-list-item",
            {
              on: {
                click: function($event) {
                  return _vm.selectType({ name: "import.customer" })
                }
              }
            },
            [
              _c(
                "v-list-item-avatar",
                [
                  _c("v-icon", { attrs: { color: "primary" } }, [
                    _vm._v("$cp_user")
                  ])
                ],
                1
              ),
              _c(
                "v-list-item-content",
                [
                  _c("v-list-item-title", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("modals.ChooseImportType.types.customer.title")
                        ) +
                        " "
                    )
                  ]),
                  _vm.$vuetify.breakpoint.smAndUp
                    ? _c("v-list-item-subtitle", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "modals.ChooseImportType.types.customer.subtitle"
                              )
                            ) +
                            " "
                        )
                      ])
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }