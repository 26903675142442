var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "tr",
    _vm._g(
      { class: _vm.clickable ? "clickable" : "" },
      {
        click: _vm.clickable
          ? _vm.onClick
          : function() {
              return null
            }
      }
    ),
    [
      _c("td", { staticClass: "py-2", attrs: { width: "40%" } }, [
        _vm.item.created_at
          ? _c("div", [_vm._v(_vm._s(_vm._f("datetime")(_vm.item.created_at)))])
          : _vm._e(),
        _c("div", [
          _vm._v(
            _vm._s(
              _vm.$t("tables.ImportEntryTable.item.created_by", {
                created_by: _vm.item.created_by.name
              })
            )
          )
        ])
      ]),
      _c("td", { staticClass: "py-2", attrs: { width: "30%" } }, [
        _vm._v(_vm._s(_vm.item.type_i18n))
      ]),
      _c(
        "td",
        { staticClass: "py-2", attrs: { width: "15%" } },
        [
          _c(
            "v-chip",
            {
              attrs: {
                label: "",
                color: _vm.item.status_color,
                outlined: "",
                small: ""
              }
            },
            [
              _vm.item.status_icon
                ? _c("v-icon", { attrs: { small: "", left: "" } }, [
                    _vm._v(_vm._s(_vm.item.status_icon))
                  ])
                : _vm._e(),
              _vm._v(" " + _vm._s(_vm.item.status_i18n) + " ")
            ],
            1
          )
        ],
        1
      ),
      _c("td", { staticClass: "py-2", attrs: { width: "15%" } }, [
        _vm.item.progress_percentage
          ? _c("div", [
              _vm._v(
                " " +
                  _vm._s(
                    _vm._f("percentage")(parseInt(_vm.item.progress_percentage))
                  ) +
                  " "
              )
            ])
          : _c("div", [_vm._v(" - ")])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }