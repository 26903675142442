<template>
    <v-container fluid class="pt-0 px-5 px-md-12">
        <div class="d-flex flex-column flex-sm-row align-sm-center justify-sm-space-between">
            <span class="pl-3 section-title">{{ $t('views.import.view.title') }}</span>
            <v-btn outlined height="36px" :width="$vuetify.breakpoint.smAndUp ? '130px' : '100%'" class="mt-6 mt-sm-0 mr-3" @click="fetchImportEntry"
                :disabled="loading" color="primary white--text"
            >
                <v-icon small class="mr-3">$cp_refresh</v-icon>
                {{ $t('actions.refresh') }}
            </v-btn>
        </div>

        <v-card flat class="mt-4 mb-2 mx-0 mx-md-1 pa-4">
            <v-card-text class="py-0">
                <v-progress-linear v-if="loading" indeterminate></v-progress-linear>

                <template v-if="importEntry">

                    <!-- Informações básicas -->
                    <v-row>
                        <v-col class="py-1">
                            {{ $t('views.import.view.info.id.label') }}
                        </v-col>
                        <v-col class="py-1">
                            {{ id }}
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col class="py-1">
                            {{ $t('views.import.view.info.type.label') }}
                        </v-col>
                        <v-col class="py-1">
                            {{ importEntry.type_i18n }}
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col class="py-1">
                            {{ $t('views.import.view.info.status.label') }}
                        </v-col>
                        <v-col class="py-1">
                            <v-chip label :color="importEntry.status_color" outlined x-small>
                                {{ importEntry.status_i18n }}
                            </v-chip>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col class="py-1">
                            {{ $t('views.import.view.info.created_at.label') }}
                        </v-col>
                        <v-col class="py-1">
                            {{ importEntry.created_at | datetime }}
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col class="py-1">
                            {{ $t('views.import.view.info.created_by.label') }}
                        </v-col>
                        <v-col class="py-1">
                            {{ importEntry.created_by.name }}
                        </v-col>
                    </v-row>

                    <v-row v-if="importEntry.file_url">
                        <v-col class="py-1">
                            {{ $t('views.import.view.info.file_url.label') }}
                        </v-col>
                        <v-col class="py-1">
                            <a :href="importEntry.file_url">
                                <v-icon small class="mr-2">$cp_file</v-icon>
                                {{ $t('views.import.view.info.file_url.value') }}
                            </a>
                        </v-col>
                    </v-row>

                    <!-- Descrição do progresso -->
                    <template v-if="importEntry.hasProgress()">
                        <v-divider class="my-3"></v-divider>

                        <h3 class="mb-4 primary--text">{{ $t('views.import.view.progress.title') }}</h3>

                        <!-- Informações adicionais -->
                        <!-- [TODO] fazer collapse -->
                        <v-row>
                            <v-col class="py-1">
                                {{ $t('views.import.view.progress.validate_progress.label') }}
                            </v-col>
                            <v-col class="py-1">
                                <div>{{ $t('views.import.view.progress.progress.value', { row: importEntry.lastValidatedRow }) }}</div>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col class="py-1">
                                {{ $t('views.import.view.progress.process_progress.label') }}
                            </v-col>
                            <v-col class="py-1">
                                <div>{{ $t('views.import.view.progress.progress.value', { row: importEntry.lastProcessedRow }) }}</div>
                                <small v-if="importEntry.lastProcessedRow">{{ $t('views.import.view.progress.process_progress.hint', { row: importEntry.lastProcessedRow }) }}</small>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col>
                                <v-progress-linear
                                    :value="importEntry.progress_percentage"
                                    height="25" rounded
                                    :color="importEntry.status_color"
                                >
                                    <template v-slot:default="{ value }">
                                        <strong>{{ parseInt(value) }}%</strong>
                                    </template>
                                </v-progress-linear>
                            </v-col>
                        </v-row>

                    </template>

                    <!-- Descrição de erros -->
                    <template v-if="importEntry.hasError()">
                        <v-divider class="my-3"></v-divider>

                        <h3 class="mb-4 primary--text">{{ $t('views.import.view.error.title') }}</h3>

                        <v-row v-if="importEntry.error_message_i18n">
                            <v-col class="pb-0 mb-2">
                                <v-alert type="error">
                                    <div>{{ importEntry.error_message_i18n }}</div>

                                    <!-- Mostra o erro de validação -->
                                    <div v-if="$lodash.get(importEntry, 'status_details.data.validationReturn')">
                                        {{ importEntry.status_details.data.validationReturn }}
                                    </div>

                                    <!-- Mostra os valores repetidos -->
                                    <template v-if="importEntry.status_details.code == 'DUPLICATED_COLUMN_VALUE'">
                                        <div>{{ importEntry.status_details.data.repeatedValues.join(', ') }}</div>
                                    </template>
                                </v-alert>
                            </v-col>
                        </v-row>

                        <v-row v-if="importEntry.status_details.message">
                            <v-col class="py-1">
                                {{ $t('views.import.view.error.message.label') }}
                            </v-col>
                            <v-col class="py-1">
                                {{ importEntry.status_details.message }}
                            </v-col>
                        </v-row>

                        <v-row v-if="importEntry.status_details.code">
                            <v-col class="py-1">
                                {{ $t('views.import.view.error.code.label') }}
                            </v-col>
                            <v-col class="py-1">
                                {{ importEntry.status_details.code }}
                            </v-col>
                        </v-row>

                        <v-row v-if="importEntry.status_details.data">
                            <v-col class="py-1">
                                {{ $t('views.import.view.error.status_details.label') }}
                            </v-col>
                            <v-col class="py-1">
                                <code class="pa-4 elevation-0 code-box">{{ importEntry.status_details.data }}</code>
                            </v-col>
                        </v-row>
                    </template>
                </template>
            </v-card-text>
        </v-card>
    </v-container>
</template>
<script>
import ImportEntry from '@/models/ImportEntry'
import HasErrorHandlerMixin from '@/mixins/HasErrorHandlerMixin'

export default {
    mixins: [ HasErrorHandlerMixin ],
    data: vm => ({
        loading       : false,
        importEntry   : null,
        interval      : null,
        INTERVAL_IN_MS: 5000,
    }),
    async created() {
        if (this.id)
            await this.fetchImportEntry()
        else
            this.$router.push({ name: 'import' })

        // Vai atualizar este registro a cada intervalo fixo
        this.interval = setInterval(() => { this.fetchImportEntry() }, this.INTERVAL_IN_MS)
    },
    beforeDestroy() {
        clearInterval(this.interval)
    },
    methods: {
        async fetchImportEntry() {
            this.importEntry = null
            this.loading     = true
            let response     = await ImportEntry.get(this.id)
                .catch(this.errorHandler)
            this.loading = false

            if (!response) {
                this.$router.push({ name: 'import' })
                return
            }

            this.importEntry = new ImportEntry(response.data)

            // Se a importação já terminou ou contém erro, o polling não é mais necessário
            if (this.importEntry.isDone() || this.importEntry.hasError())
                clearInterval(this.interval)
        },
    },
    computed: {
        id() {
            return this.$route.params.id
        },
    },
}
</script>

<style scoped lang="scss">
.v-card:not(.v-sheet--tile):not(.v-card--shaped), .code-box {
    border-radius: 8px !important;
}
</style>