var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "pt-0", attrs: { fluid: "" } },
    [
      _c("ChooseImportTypeModal", { ref: "choose_import_type_modal" }),
      _c("span", { staticClass: "pl-5 pl-md-12 section-title" }, [
        _vm._v(_vm._s(_vm.$t("views.import.List.title")))
      ]),
      _vm.hasError
        ? _c(
            "v-alert",
            {
              staticClass: "mt-4 mx-5 mt-md-6 mx-md-12",
              attrs: { type: "error" }
            },
            [_vm._v(" " + _vm._s(_vm.$t("errors.generic")) + " ")]
          )
        : _c("ImportEntryTable", {
            ref: "table",
            staticClass: "my-4 mx-2 mx-md-10",
            attrs: { fetchFunction: _vm.fetchFunction },
            on: {
              openImport: _vm.handleOpenImport,
              selected: _vm.viewImportEntry,
              refresh: _vm.refreshTable
            }
          })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }