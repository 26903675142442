<template>
    <BaseCrudTable 
        ref="table"
        tableName="ImportEntryTable"
        :headerConfigs="headerConfigs"
        :fetchFunction="fetchFunction"
        :pagination="pagination"
        :createBtnHidden="true"
        :showActions="false"
        @update:loading="value => loading = value"
    >
        <template #actions>
            <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn v-can:create="'ImportEntry'" icon small class="mr-0 mr-sm-4" @click="$emit('refresh')" v-bind="attrs" v-on="on">
                        <v-icon small class="secondary_font--text">$cp_refresh</v-icon>
                    </v-btn>
                </template>
                <span>{{ $t('actions.import') }}</span>
            </v-tooltip>
        </template>
        
        <template #main-btn>
            <v-btn @click="$emit('openImport')" :block="!$vuetify.breakpoint.smAndUp" outlined :loading="loading" height="36px" width="86px" :class="$vuetify.breakpoint.smAndUp ? 'mr-3 primary--text' : 'primary--text'" >{{ $t('views.import.List.add') }}</v-btn>
        </template>

        <template #item="props">
            <ImportEntryTableItem clickable @click="$emit('selected', props)" v-bind="props"/>
        </template>
    </BaseCrudTable>
</template>

<script>
import BaseCrudTable        from '@/components/tables/BaseCrudTable'
import ImportEntryTableItem from './ImportEntryTableItem'

export default {
    name: 'ImportEntryTable',
    props: {
        fetchFunction: {
            type: Function,
            required: true,
        },
    },
    components: { BaseCrudTable, ImportEntryTableItem },
    data: () => {
        return {
            pagination: {
                page    : 1,
                per_page: 5,
            },
            headerConfigs: [
                {
                    value: 'created_at', 
                    showOnMobile: true,
                },
                {
                    value: 'type',
                    showOnMobile: false,
                },
                {
                    value: 'status',
                    showOnMobile: true,
                },
                {
                    value: 'progress',
                    showOnMobile: true,
                },
            ],
            loading: false,
        }
    },
    methods: {
        async refresh() {
            await this.$refs.table.preFetchItems()
        },
    },
}
</script>